<template>
  <div class="d-row site-background admin">
    <div class="overlay" v-if="showingOverlay">
      <div v-if="loading">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <navbar manager="true" admin="true"></navbar>
    <div class="container">
      <div class="route-section">
        <div class="form-container">
          <div class="form-group">
            <select
              v-model="modelType"
              id="modelType"
              class="chapter-title-input story-form-input"
            >
              <option disabled selected>Select Module</option>
              <option value="users">User</option>
              <option value="print-orders">Print</option>
              <option value="saved-stories">Saved Stories</option>
              <option value="stories-template">Stories Template</option>
            </select>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <datepicker
                  input-class="datep-gift-input gift-title-input chapter-control recreate-form-input"
                  calendar-class="register-calendar"
                  v-model="fromDate"
                  name="from"
                  ref="from"
                  format="MM/dd/yyyy"
                  placeholder="From Date"
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="form-group">
            <datepicker
              input-class="datep-gift-input gift-title-input chapter-control recreate-form-input"
              calendar-class="register-calendar"
              v-model="toDate"
              name="to"
              ref="to"
              format="MM/dd/yyyy"
              placeholder="To Date"
            ></datepicker>
          </div>
          <div class="form-group">
            <button class="btn recreate-btn" @click="exportModel">
              Export
            </button>
          </div>
          <div v-if="displayErrorNotFound" class="error-message">
            <h3>{{ ErrorNotFoundMessage }}</h3>
          </div>

          <div v-if="displaySuccessMessage" class="success-message">
            <h4>{{ successMessage }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "../components/navbar.vue"
import { serverUrl } from "../severUrl"
import dayjs from "dayjs"
import Datepicker from "vuejs-datepicker"
import axiosInstance from "../axiosInstance.js"

export default {
  created() {},
  components: {
    navbar,
    Datepicker,
  },
  data() {
    return {
      modelSearch: "",
      fromDate: "",
      toDate: "",
      modelType: "",
      showingOverlay: false,
      loading: false,
      ErrorNotFoundMessage: "",
      displayErrorNotFound: false,
      successMessage: "",
      displaySuccessMessage: false,
      errorMessage: "",
      displayErrorMessage: false,
      noResult: true,
    }
  },
  methods: {
    initSearch: function () {
      this.ErrorNotFoundMessage = ""
      this.successMessage = ""
      this.errorMessage = ""
      this.displayErrorMessage = false
      this.noResult = true
    },

    exportModel: async function (backToList = false) {
      try {
        this.backToList = backToList
        this.loading = true
        this.showingOverlay = true
        this.displayErrorNotFound = false
        this.displayErrorMessage = false
        this.displaySuccessMessage = false
        let response
        if (this.modelType) {
          response = await axiosInstance.post(
            serverUrl + "/api/main/export/" + this.modelType,
            { from: this.fromDate, to: this.toDate },
            {
              withCredentials: true,
            }
          )
        }

        if (response.status == 200) {
          this.noResult = false
          if (this.modelType === "users") {
            this.successMessage = response?.data?.message
            this.displaySuccessMessage = true
          } else {
            this.downloadFile(response.data.link)
          }
          this.fromDate = ""
          this.toDate = ""
        } else {
          this.ErrorNotFoundMessage = "Server Error"
          this.displayErrorNotFound = true
          this.noResult = true
        }

        this.loading = false
        this.showingOverlay = false
      } catch (error) {
        this.ErrorNotFoundMessage = "Server error"
        this.displayErrorNotFound = true
        this.loading = false
        this.showingOverlay = false
        this.noResult = true
      }
    },
    downloadFile(link) {
      const url = `${serverUrl}${link}` // Replace with your API base URL
      const a = document.createElement("a")
      a.href = url
      a.download = "report.csv" // The file name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
  },
  filters: {
    formatDate: (date) => {
      if (!date) {
        return null
      }
      return dayjs(date).format("MM/DD/YYYY")
    },
  },
  computed: {
    // filteredOrders() {
    //   return this.calculateEndDate(this.order)
    // },
    formattedFromDate() {
      return this.filters.formattedFromDate(this.fromDate)
    },
    formattedToDate() {
      return this.filters.formattedToDate(this.toDate)
    },
  },
}
</script>
<style>
.btn {
  padding: 10px 23px;
  color: rgba(6, 42, 32, 0.4);
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
  margin-right: 10px;
}

.btn:hover {
  cursor: pointer;
}

.btn.active {
  background: #0f172a;
  color: white;
  font-weight: 500;
}

.story-form-input {
  padding: 20px;
  margin-right: 10px;
}

.input-form {
  padding: 10px;
  border: solid 1px #00000017;
}

.table-title-element {
  margin-right: 5px;
  text-align: center;
  min-width: 151px;
  margin-left: 10px;
}

.recreacte-row {
  padding: 10px;
}

.recreacte-row span {
  margin-left: 10px;
}

.recreate-form-input {
  padding: 10px;
  margin-left: 10px;
}

.recreate-form-textarea {
  margin-left: 10px;
}

.table-title-element {
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  text-overflow: ellipsis; /* Ajoute des points de suspension à la fin du texte qui dépasse */
  min-width: 200px; /* Définir une largeur minimale pour chaque colonne */
}

.number-input {
  position: relative;
  display: inline-block;
}

.number-input input {
  width: 100px;
  padding: 5px 25px;
  text-align: center;
}

.number-input button {
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  border: none;
  background: none;
  cursor: pointer;
}

.number-input .increment {
  right: 0;
}

.number-input .decrement {
  left: 0;
}
</style>

<style scoped>
.d-row {
  display: flex;
}

.form-container {
  margin-left: 12px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
}

.error-message {
  margin-top: 25px;
  color: red;
}

.multi-order-recip {
  margin-top: 18px;
  background: #ffffff;
  border: 1px solid rgba(6, 42, 32, 0.2);
  box-sizing: border-box;
  border-radius: 30px;
  min-height: 300px;
  overflow-y: auto;
  padding: 40px 27px;
}

.back-to-list {
  margin-top: 18px;
  font-size: x-large;
}

.success-message {
  color: green;
  margin-top: 20px;
}
</style>
