import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/index"
import axiosInstance from "./axiosInstance.js"

Vue.prototype.URL = window.URL || window.webkitURL

Vue.config.productionTip = false
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 503) {
      console.log("Maintenance mode detected")
      router.push("/maintenance")
    }
    return Promise.reject(error)
  }
)
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app")
