<template>
  <div class="maintenance-page">
    <img
      src="../assets/logo - black.png"
      alt="My Life in a Book Logo"
      class="logo"
    />
    <img :src="maintenanceIcon" alt="Maintenance Icon" class="icon" />
    <h1 class="static-text">Our application is actually under maintenance.</h1>
    <p class="static-text">We expect to be back as soon as possible.</p>
    <p class="static-text">
      While the application is being upgraded, you can visit our website by
      clicking <a href="https://mylifeinabook.com"><b>here</b></a>
    </p>
    <!-- Gorgias Widget Embed Code -->
    <div id="gorgias-widget"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maintenanceIcon: require("../assets/maintenance-icon.svg"), // Path to your uploaded SVG file
    }
  },
  mounted() {},
}
</script>

<style scoped>
.maintenance-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
}

.logo {
  width: 100px;
}

.icon {
  display: block;
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
}

.static-text {
  font-size: 18px;
  color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .maintenance-page {
    padding: 30px;
  }

  .logo {
    width: 80px;
  }

  .icon {
    max-width: 600px;
  }

  .static-text {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .maintenance-page {
    padding: 20px;
  }

  .logo {
    width: 60px;
  }

  .icon {
    max-width: 400px;
  }

  .static-text {
    font-size: 14px;
  }
}
</style>
